<template>
  <v-text-field
    label="Nome da Localidade"
    v-model="nomeLocalidade"
    :autofocus="false"
    v-validate="'required|min:2|max:255'"
    :rules="[
      () => !!nomeLocalidade || 'Preenchimento obrigatório!',
      nomeLocalidade => nomeLocalidade.length >= 2 || 'Mín. 2 caracteres',
      nomeLocalidade => nomeLocalidade.length <= 255 || 'Máx. 255 caracteres',
    ]"
    required
  ></v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    nomeLocalidade: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>