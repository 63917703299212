<template>
  <v-text-field
    :label="label"
    v-model="inputValue"
    :autofocus="false"
    v-validate="'required|decimal:11'"
    v-mask="'-##.###########'"
    :rules="[
      () => !!inputValue || 'Preenchimento obrigatório!',
      inputValue => (!isNaN(parseInt(inputValue))) || 'Deve ser um número e conter no máximo 11 casas decimais',
    ]"
    required
  ></v-text-field>
</template>

<script>
export default {
  props: ['value', 'label'],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>