<template>
  <v-text-field
    label="Referência"
    v-model="inputValue"
    :autofocus="false"
    v-validate="'max:255'"
    :rules="[
      inputValue => inputValue.length <= 255 || 'Máx. 255 caracteres',
    ]"
    required
  ></v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>