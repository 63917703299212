<template>
  <v-select
    :items="bairros"
    item-text="nome"
    item-value="id"
    label="Bairro"
    v-validate="'required'"
    v-model="idBairro"
  ></v-select>
</template>

<script>
import { readBairros } from '@/store/bairro/getters';

export default {
  props: ['value'],
  computed: {
    idBairro: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      bairros: [],
    };
  },
  mounted() {
    this.bairros = readBairros(this.$store);
  },
};
</script>