<template>
  <v-text-field
    label="Logradouro"
    v-model="logradouro"
    :autofocus="false"
    v-validate="'required|min:2|max:255'"
    :rules="[
      () => !!logradouro || 'Preenchimento obrigatório!',
      logradouro => logradouro.length >= 2 || 'Mín. 2 caracteres',
      logradouro => logradouro.length <= 255 || 'Máx. 255 caracteres',
    ]"
    required
  ></v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    logradouro: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>