<template>
  <v-select
    :items="tiposLogradouro"
    item-text="descricao"
    item-value="id"
    label="Tipo de logradouro"
    v-validate="'required'"
    v-model="inputValue"
  ></v-select>
</template>

<script>
import { readTiposLogradouro } from '@/store/tipo_logradouro/getters';

export default {
  props: ['value'],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      tiposLogradouro: [],
    };
  },
  mounted() {
    this.tiposLogradouro = readTiposLogradouro(this.$store);
  },
};
</script>