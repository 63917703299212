<template>
  <v-select
    :items="distritos"
    item-text="nome"
    item-value="id"
    label="Distrito"
    v-validate="'required'"
    v-model="idDistrito"
  ></v-select>
</template>

<script>
import { readDistritos } from '@/store/distrito/getters';

export default {
  props: ['value'],
  computed: {
    idDistrito: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      distritos: [],
    };
  },
  mounted() {
    this.distritos = readDistritos(this.$store);
  },
};
</script>