<template>
  <v-select
    :items="statusImplantacao"
    item-text="nome"
    item-value="id"
    label="Status da implantação"
    v-validate="'required'"
    v-model="idStatusImplantacao"
  ></v-select>
</template>

<script>
import { readStatusImplantacao } from '@/store/status_implantacao/getters';

export default {
  props: ['value'],
  computed: {
    idStatusImplantacao: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      statusImplantacao: [],
    };
  },
  mounted() {
    this.statusImplantacao = readStatusImplantacao(this.$store);
  },
};
</script>