<template>
  <v-select
    :items="tiposLocalidade"
    item-text="descricao"
    item-value="id"
    label="Tipo da localidade"
    v-validate="'required'"
    v-model="idTipoLocalidade"
  ></v-select>
</template>

<script>
import { readTiposLocalidade } from '@/store/tipo_localidade/getters';

export default {
  props: ['value'],
  computed: {
    idTipoLocalidade: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      tiposLocalidade: [],
    };
  },
  mounted() {
    this.tiposLocalidade = readTiposLocalidade(this.$store);
  },
};
</script>