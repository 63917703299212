<template>
  <v-text-field
    label="Número"
    v-model="numeroLogradouro"
    :autofocus="false"
    v-validate="'numeric'"
    :rules="[
      numeroLogradouro => numeroLogradouro.length > 0 && isNaN(parseInt(numeroLogradouro)) && 'Deve ser um número',
    ]"
  ></v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    numeroLogradouro: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>