<template>
  <v-text-field
    label="CEP"
    v-model="cep"
    :autofocus="false"
    v-validate="'required|length:8'"
    :rules="[
      () => !!cep || 'Preenchimento obrigatório!',
      cep => cep.length == 8 || 'Deve conter 8 caracteres',
      cep => (!isNaN(parseInt(cep))) || 'Deve ser um número',
    ]"
    required
  ></v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    cep: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>