<template>
  <v-text-field
    label="Código da localidade"
    v-model="codigoLocalidade"
    :autofocus="true"
    v-validate="'required|min:2|max:10'"
    :rules="[
      () => !!codigoLocalidade || 'Preenchimento obrigatório!',
      codigoLocalidade => codigoLocalidade.length >= 2 || 'Mín. 2 caracteres',
      codigoLocalidade => codigoLocalidade.length <= 10 || 'Máx. 10 caracteres',
    ]"
    required
  ></v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    codigoLocalidade: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>