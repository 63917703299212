<template>
  <v-text-field
    label="Complemento"
    v-model="complementoLogradouro"
    :autofocus="false"
    v-validate="'max:255'"
    :rules="[
      complementoLogradouro => {
        if (complementoLogradouro && complementoLogradouro.length > 255) {
          return 'Máx. 255 caracteres';
        }
        return true
      },
    ]"
  ></v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    complementoLogradouro: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>