<template>
  <v-select
    :items="origensLocalidade"
    item-text="nome"
    item-value="id"
    label="Origem da localidade"
    v-validate="'required'"
    v-model="idOrigemLocalidade"
  ></v-select>
</template>

<script>
import { readOrigemLocalidadeLista } from '@/store/origem_localidade/getters';

export default {
  props: ['value'],
  computed: {
    idOrigemLocalidade: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      origensLocalidade: [],
    };
  },
  mounted() {
    this.origensLocalidade = readOrigemLocalidadeLista(this.$store);
  },
};
</script>