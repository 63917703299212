<template>
  <v-select
    :items="responsaveis"
    item-text="nome"
    item-value="id"
    label="Responsável"
    v-validate="'required'"
    v-model="idResponsavel"
  ></v-select>
</template>

<script>
import { readResponsaveisPorLocalidade } from '@/store/responsavel/getters';

export default {
  props: ['value'],
  computed: {
    idResponsavel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      responsaveis: [],
    };
  },
  mounted() {
    this.responsaveis = readResponsaveisPorLocalidade(this.$store);
  },
};
</script>