<template>
  <div>
    <v-layout row>
      <v-flex md2 pa-1>
        <CodigoLocalidade v-model="localidade.codigo_localidade" />
      </v-flex>
      <v-flex md7 pa-1>
        <NomeLocalidade v-model="localidade.nome" />
      </v-flex>
      <v-flex md3 pa-1>
        <ReferenciaLocalidade v-model="localidade.referencia" />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md2 pa-1>
        <TipoLogradouro v-model="localidade.id_tipo_logradouro" />
      </v-flex>
      <v-flex md6 pa-1>
        <NomeLogradouro v-model="localidade.logradouro" />
      </v-flex>
      <v-flex md1 pa-1>
        <NumeroLogradouro v-model="localidade.numero_logradouro" />
      </v-flex>
      <v-flex md3 pa-1>
        <ComplementoLogradouro v-model="localidade.complemento_logradouro" />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md3 pa-1>
        <Bairro v-model="localidade.id_bairro" />
      </v-flex>
      <v-flex md3 pa-1>
        <CEP v-model="localidade.cep" />
      </v-flex>
      <v-flex md3 pa-1>
        <LatLong v-model="localidade.latitude" label="Latitude" />
      </v-flex>
      <v-flex md3 pa-1>
        <LatLong v-model="localidade.longitude" label="Longitude" />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md4 pa-1>
        <OrigemLocalidade v-model="localidade.id_origem_localidade" />
      </v-flex>
      <v-flex md4 pa-1>
        <TipoLocalidade v-model="localidade.id_tipo_localidade" />
      </v-flex>
      <v-flex md4 pa-1>
        <Responsavel v-model="localidade.id_responsavel" />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md4 pa-1>
        <Regiao v-model="localidade.id_regiao" />
      </v-flex>
      <v-flex md4 pa-1>
        <Subprefeitura v-model="localidade.id_subprefeitura" />
      </v-flex>
      <v-flex md4 pa-1>
        <Distrito v-model="localidade.id_distrito" />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md6 pa-1>
        <StatusImplantacao v-model="localidade.id_status_implantacao" />
      </v-flex>
      <v-flex md6 pa-1>
        <TipoPublicidade v-model="localidade.id_tipo_publicidade" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import CodigoLocalidade from '@/components/inputs/CodigoLocalidade.vue';
import NomeLocalidade from '@/components/inputs/NomeLocalidade.vue';
import ReferenciaLocalidade from '@/components/inputs/ReferenciaLocalidade.vue';
import TipoLogradouro from '@/components/inputs/TipoLogradouro.vue';
import NomeLogradouro from '@/components/inputs/NomeLogradouro.vue';
import NumeroLogradouro from '@/components/inputs/NumeroLogradouro.vue';
import ComplementoLogradouro from '@/components/inputs/ComplementoLogradouro.vue';
import Bairro from '@/components/inputs/Bairro.vue';
import CEP from '@/components/inputs/CEP.vue';
import LatLong from '@/components/inputs/LatLong.vue';
import OrigemLocalidade from '@/components/inputs/OrigemLocalidade.vue';
import TipoLocalidade from '@/components/inputs/TipoLocalidade.vue';
import Responsavel from '@/components/inputs/Responsavel.vue';
import Regiao from '@/components/inputs/Regiao.vue';
import Subprefeitura from '@/components/inputs/Subprefeitura.vue';
import Distrito from '@/components/inputs/Distrito.vue';
import StatusImplantacao from '@/components/inputs/StatusImplantacao.vue';
import TipoPublicidade from '@/components/inputs/TipoPublicidade.vue';

export default {
  components: {
    CodigoLocalidade,
    NomeLocalidade,
    ReferenciaLocalidade,
    TipoLogradouro,
    NomeLogradouro,
    NumeroLogradouro,
    ComplementoLogradouro,
    Bairro,
    CEP,
    LatLong,
    OrigemLocalidade,
    TipoLocalidade,
    Responsavel,
    Regiao,
    Subprefeitura,
    Distrito,
    StatusImplantacao,
    TipoPublicidade,
  },
  props: ['value'],
  computed: {
    localidade: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>